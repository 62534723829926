/*noinspection CssInvalidAtRule*/
@import-normalize ;

html {
  font-size: 16px;
  color: #3c3c3b;
  margin-left: calc(100vw - 100%);
  height: 100%;
}

body,
#root {
  height: 100%;
  width: 100%;
  font-weight: 500;
  color: #3c3c3b;
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif;
}

body,
select,
input,
button,
a,
p {
  margin: 0;
  outline: none;
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinAnim {
  animation: spin 2s linear;
}

.icon-rotating {
  -webkit-animation: spin 1.2s linear infinite;
  -moz-animation: spin 1.2s linear infinite;
  animation: spin 1.2s linear infinite;
}

/* Helpers */

.-alignCenter {
  text-align: center !important;
}

.-flexColumn {
  display: flex;
  flex-flow: column nowrap;
}

.-flexRow {
  display: flex;
  flex-flow: row nowrap;
}

.-fullWidth {
  width: 100%;
}

a, a:visited, a:focus {
  color: #5ABDD7;
  text-decoration: none;
  text-underline: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.Toastify__fade-enter {
  animation-name: fadeIn;
}

.Toastify__fade-exit {
  animation-name: fadeOut;
}
